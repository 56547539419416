<template>
    <router-view/>
</template>

<script>
    import {defineComponent, getCurrentInstance} from 'vue';

    export default defineComponent({
        name: 'App',
        setup() {
            const currentInstance = getCurrentInstance();
            const {$store} = currentInstance.appContext.config.globalProperties;

            $store.dispatch('getUserInfo');
        },

    })
</script>

<style>
    /*放公共CSS文件*/
    @import "./assets/css/main.css";
    @import "./assets/css/color-dark.css";
</style>
