import {createStore} from 'vuex';

import $router from '@/router'
import $axios from '@/axios'


export default createStore({
    state(){
        return {
            collapse:false,      // 折叠状态
            username:undefined, // 用户名
            getPicBaseUrl:'https://www.hagonne.com/images/',  // 静态服务图片前缀 - 服务器上使用
            // getPicBaseUrl:'http://localhost:3000',     // 静态服务图片前缀 - 本地测试
            action:'/api/receivePic',       // 上传图片的接口路径
            action2:'/api/receivePic2',     // 上传图片的接口路径(公司简介、产品详情、方案详情专用)
            image_size:0.5*1024*1024,       // wangEditor 单个图片的大小,默认为2M，左边示例是0.5M
            base64LimitSize:5*1024,         // wangEditor 小于该值就插入base64格式（而不上传），默认为0。左边示例是5KB。
            image_timeout:5*1000,           // wangEditor 上传图片超时时间，默认10秒，左边示例是5秒
            imageMaxNumber:1,        // wangEditor 一次最多可上传几张图片，默认100个，左边是1个
        }
    },
    mutations:{
        // 修改折叠状态
        handleCollapse(state,data){
            state.collapse = data;
        },
        // 修改用户名
        setUsername(state,data){
            state.username = data;
        },
    },
    actions:{
        // 侧边栏折叠
        handleCollapse({commit},data){
            commit('handleCollapse',data);
        },

        // 登录成功
        async login({commit},obj){
            commit('setUsername',obj.username);

            $router.push('/');
        },

        // 获取用户信息
        async getUserInfo({commit}){
            const {data} = await $axios({url:'/getUserInfo',});
            if(data.code === 200){
                commit('setUsername',data.data.username);

                $router.push(location.pathname);
            }else{
                commit('setUsername',undefined);

                $router.push({path:'/login'});
            }
        },

        // 退出系统
        async logout({commit}){
            commit('setUsername',undefined);

            $router.push({path:'/login'});
        },
    },
    getters:{
        // 返回折叠状态
        getCollapse(state){
            return state.collapse;
        },
        // 返回用户名
        getUsername(state){
            return state.username;
        },
        // 返回要显示的图片前缀
        getPicBaseUrl(state){
            return state.getPicBaseUrl;
        },
        // 返回上传的图片前缀
        getActionUrl(state){
            return state.action;
        },
        // 返回上传的图片前缀(公司简介、产品详情、方案详情专用)
        getActionUrl2(state){
            return state.action2;
        },
        // 返回wangEditor编辑器限制图片的大小
        getImageSize(state){
            return state.image_size;
        },
        // 返回wangEditor小于该值就插入base64格式（而不上传图片）
        getBase64Size(state){
            return state.base64LimitSize;
        },
        // 返回wangEditor上传图片超时时间
        getImageTimeout(state){
            return state.image_timeout;
        },
        // 返回wangEditor每次上传图片的个数
        getImageMaxNumber(state){
            return state.imageMaxNumber;
        },
    }
});
