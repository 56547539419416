import {createRouter,createWebHistory} from 'vue-router'

const routes = [
    {
        path:'/login',
        name:'login',
        component:()=>import('@/views/Login')
    },
    {
        path:'/',
        name:'home',
        component:()=>import('@/components/common/Home'),
        children:[
            {
                path:'/dashboard',
                name:'dashboard',
                component:()=>import('@/views/Dashboard')
            },
            {
                path:'/navigator',
                name:'navigator',
                component:()=>import('@/views/Navigator')
            },
            {
                path:'/category',
                name:'category',
                component:()=>import('@/views/Category')
            },
            {
                path:'/goods',
                name:'goods',
                component:()=>import('@/views/Goods')
            },
            {
                path:'/plan',
                name:'plan',
                component:()=>import('@/views/Plan')
            },
            {
                path:'/apply',
                name:'apply',
                component:()=>import('@/views/Apply')
            },
            {
                path:'/feedback',
                name:'feedback',
                component:()=>import('@/views/Feedback')
            },
            {
                path:'/profile',
                name:'profile',
                component:()=>import('@/views/Profile')
            },
            {
                path:'/user',
                name:'user',
                component:()=>import('@/views/User')
            },
        ],
    }
];

const router = createRouter({
    history:createWebHistory('/'),
    routes,
});



export default router;
